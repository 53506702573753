<script lang="ts" setup>
import { useStorage } from "@vueuse/core";

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const { t } = useT();
const localPath = useLocalePath();
const { open } = useFunrizeModals();
const { durationLeft, hasDailyReward, statusActivateReward, claimIsActive, claimWeeklyReward } = useDailyPlayback();
const isCashbackCalculation = useStorage<boolean>("isCashbackCalculation", false);

const availableCalculation = computed(() => isCashbackCalculation.value && hasDailyReward.value);

const handleClick = () => {
	if (availableCalculation.value) {
		return;
	}

	emit("toggleSidebar");

	if (claimIsActive.value) {
		claimWeeklyReward();
		return;
	}

	if (hasDailyReward.value) {
		navigateTo(localPath("/issues/all-games/"));
		return;
	}

	open("LazyOModalDailyPlaybackRules");
};
</script>

<template>
	<div :class="['playback-banner', { calc: availableCalculation }]" @click="handleClick">
		<div class="content">
			<AText class="text-conakry" :modifiers="['bold']">
				{{ t("playBack.title") }}
			</AText>
			<template v-if="!claimIsActive">
				<AText v-if="availableCalculation" :size="12" class="text-cannes">
					{{ t("playBack.txt.calc") }}
				</AText>
				<AText v-else-if="hasDailyReward" :size="12" class="text-cannes">{{ durationLeft }}</AText>
				<AText v-else :size="10" class="text-cannes">
					{{ t("playBack.unlock.level", { level: statusActivateReward }) }}
				</AText>
			</template>
		</div>
		<AButton v-if="claimIsActive" class="btn" variant="primary" size="xs">
			<AText :modifiers="['bold', 'uppercase']">{{ t("playBack.btn.get") }}</AText>
		</AButton>
		<NuxtImg
			class="playback-img"
			src="/nuxt-img/playback/sidebar-icon.webp"
			format="webp"
			alt="playback-pocket"
			width="200"
			height="224"
			loading="lazy"
		/>
	</div>
</template>

<style scoped lang="scss">
.playback-banner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	height: 54px;
	background: var(--goma);
	border-radius: 8px;
	position: relative;
	padding-left: 8px;
	cursor: pointer;

	&.calc {
		cursor: not-allowed;
	}

	.playback-img {
		width: 50px;
		height: 56px;
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.btn {
		width: 60px;
		border-radius: 50px;
		border-color: var(--chimoio);
		height: 34px;
		padding-bottom: 7px;
	}
}
</style>
